import { Option } from "../features/NotebookForm/model/interface";

export const arrayToOptions = (arr: Array<any>): Option[] => {
    return arr.map<Option>(item => {return { value: item }});
}

export const makeModelFormLaptop = (laptop: any) => {
    return `${laptop.manufacturer} ${laptop.name}`;
}

export const makeDescriptionFromLaptop = (laptop: any, price: any): string => {
    let output = `Стоимость в базе: ${price} р.\n`;

    for (let key in laptop) {
        switch (key) {

            case "cpu":
                const { manufacturer, model } = laptop[key];
                output += `Процессор: ${manufacturer} ${model}\n`; 
                break;

            case "graphics_card":
                output += `Видеокарта: ${laptop[key].name}\n`; 
                break;

            
            case "ram":
                const { size } = laptop[key];
                output += `Оперативка: ${size} Гб\n`; 
                break;

            case "os":
                output += `ОС: ${laptop[key].name}\n`; 
                break;

            case "display":
                const { diagonal, resolution } = laptop[key];
                output += `Экран: ${diagonal}", ${resolution}`; 
                break;

            case "memory_hdd":
                output += `HDD: ${laptop[key].size}\n`; 
                break;

            case "memory_ssd":
                output += `SSD: ${laptop[key].size}\n`; 
                break;
        }
    }

    return output;
}