/* eslint-disable no-restricted-globals */
import { ChangeEventHandler, useState } from "react";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { makeDescriptionFromLaptop, makeModelFormLaptop } from "../../shared/utils";
import { IStoreOrderDto, OrderService } from "../../shared/services/order.service";
import { Preloader } from "../../shared/components/Preloader/Preloader";

export const ContactForm = () => {
  const laptop = useSelector((state: RootState) => state.notebookForm.laptop);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event?.target.value);
  };
  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPhone(event?.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("submit debug ===> ", laptop);

    const data:IStoreOrderDto = {
      phone,
      fio: name,
      model: makeModelFormLaptop(laptop.laptop),
      description: makeDescriptionFromLaptop(laptop.laptop, laptop.price),
    }
    setLoading(true);
    //Отпарвляем заявку
    await OrderService.storeOrder(data);

    // validatePhone
    //console.log(name, phone);

    //Переходим на страницу "Спасибо"
    location.href = "https://notecash.ru/thank-you/";
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="request__form request__form--mod"
      // action="#" method="post" encType="multipart/form-data"
    >
      <div className="request__form-group">
        <input
          className="request__input"
          id="name"
          type="text"
          placeholder="Ваше имя"
          value={name}
          onChange={handleNameChange}
        />
        <InputMask
          className="request__input"
          id="phone-2"
          name="phone"
          type="tel"
          placeholder="Введите Ваш телефон"
          mask="8 (999) 999-99-99"
          autoComplete="off"
          required
          value={phone}
          onChange={handlePhoneChange}
        />
      </div>
      <div className="request__form-btn">
        <button className="request__btn">
          {!loading && <span>Отправить</span>}
          {loading && <Preloader />}
        </button>
      </div>
    </form>
  );
};
