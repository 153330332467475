import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";

import { AppDispatch, RootState } from "../../../app/store";

import { NotebookFormValues, Option } from "../model/interface";
import { changeField } from "../model/reducer";

import { Tooltip } from "../../../shared/components/Tooltip/Tooltip";

import "./StyledSelect.css";
import { ActionMeta, SingleValue } from "react-select";
import { useState } from "react";

type FieldProps = {
  label: string;
  name: keyof NotebookFormValues;
  options?: Option[];
  tooltip?: boolean;
  remoteSearchHandler?: any;
  postfix?: string;
};

export const SelectField = ({ label, name, options=[], tooltip, remoteSearchHandler }: FieldProps) => {
  const dispatch = useDispatch<AppDispatch>();

  
  const value = useSelector((state: RootState) => state.notebookForm.fields[name]);

  
  //console.log(value);

  const [inputValue, setInputValue] = useState("");
  const [currentOptions, setCurrentOptions] = useState<Option[]>([]);

  const handleChange = (value: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    //console.log("fired")
    dispatch(changeField({ label: name, value: value as Option }));
  };

  const handleInputChange = (value: string) => {
    //console.log(value);
    setInputValue(value);
  }

  //Достает из переданных опций
  const fetchOptions = (value: string): Promise<Option[]> => {
    if (value && value.length) {
      return Promise.resolve(options.filter((item: Option) => String(item.value).toLowerCase().match(new RegExp(`^${value.toLowerCase()}`))));
    }

    return Promise.resolve(options);
  };

  //Фильтрует любые подгруженные опции
  const filterOptions = (value: string): Promise<Option[]> => {
    if (value && value.length) {
      return Promise.resolve(currentOptions.filter((item: Option) => String(item.value).toLowerCase().match(new RegExp(`^${value.toLowerCase()}`))));
    }

    return Promise.resolve(options);
  };

  //Общая функция для поиска по опциям
  const searchOptions = async (value: string): Promise<Option[]> => {
    console.log(currentOptions);
    //Если уже есть подгруженные опции
    switch (currentOptions.length > 0) {
      case true:
        let found = await filterOptions(value);
        if (!found.length && remoteSearchHandler) {//если в текущих опциях ничего не найдено и определена функция поиска к серверу, ищем на сервере
          found = await remoteSearchHandler(value);
          setCurrentOptions(found);
          return found;
        } else {
          setCurrentOptions(found);
          return found;
        }
        break;
      case false:
        if (remoteSearchHandler) {
          let found = await remoteSearchHandler(value);
          setCurrentOptions(found);
          return found;
        } else {
          return fetchOptions(value);
        }
    }
  }

  return (
    <div className="select__item">
      <label className="select__label" htmlFor={`select-${name}`}>
        {label}
        {tooltip && <Tooltip />}
      </label>
      <div className="select__body">
        <AsyncSelect
          key={value?.value}
          id={`select-${name}`}
          isSearchable
          isClearable
          closeMenuOnSelect
          blurInputOnSelect
          controlShouldRenderValue
          className="react-select-container"
          classNamePrefix="react-select"
          noOptionsMessage={() => inputValue ? "Ничего не найдено, попробуйте изменить запрос" : "Начните печатать текст запроса"}
          placeholder="Выберите из списка или начните печатать"
          defaultOptions={options.length ? options : true}
          loadOptions={searchOptions}
          getOptionLabel={(option) => String(option.value)}
          getOptionValue={(option) => String(option.value)}
          value={value}
          onChange={handleChange}
          onInputChange={handleInputChange}
        />
      </div>
    </div>
  );
};
