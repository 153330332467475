import { IApi } from "../api/api";
import { api } from "../api/notelab.api";
import { IService } from "./service";

export class OrderServiceClass implements IService {
    public api: IApi;

    public baseUrl: string = "/order/calc/";

    protected inProgress: boolean = false;

    constructor(_api?: IApi) {
        this.api = _api ?? api;
    }

    storeOrder = async (data: IStoreOrderDto) => {
        if (!this.inProgress) {
            this.inProgress = true;

            return await this.api.makeRequest<any>(this.baseUrl, { method: "POST", body: JSON.stringify(data) });
            
        } else {
            console.log("[DEBUG] Debouncing...");
            return;
        }
    }
}

export interface IStoreOrderDto {
    phone: string;

    fio: string;

    model: string;

    description: string;
}

export const OrderService = new OrderServiceClass;