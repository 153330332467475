const BASE_URL = "https://note-lab.ru/wp-json/nl/v1";

export const api = {
    makeRequest: async (url: string, options: RequestInit | undefined) => {
          const response = await fetch(`${BASE_URL}${url}`, {
            headers: {
              "Content-Type": "application/json",
            },
            ...options,
        });
        console.log(response);
        return await response.json();
    }
}